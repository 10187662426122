import AlphaHelpers from '@nematis/wp-alpha/assets/js/abstracts/classes/alpha-helpers'

function newsletterSubscribeSetFeedback(fieldEl, type = 'valid', message = '') {
  const feedbackFieldClass = `is-${type}`
  const feedbackElClass = `${type}-feedback`

  let feedbackEl = fieldEl.parentElement.querySelector(`.${feedbackElClass}`)
  if (!feedbackEl) {
    feedbackEl = document.createElement('div')
    feedbackEl.classList.add(feedbackElClass, 'mt-3')
  }

  if (message.trim() === '') {
    fieldEl.classList.add('is-valid', 'is-invalid')
    feedbackEl.remove()
    return
  }

  if (type === 'valid') {
    fieldEl.classList.remove('is-invalid')

    // automatically close exit overlay after a small delay if necessary
    const exitOverlayBlockEl = fieldEl.closest('.exit-overlay-block')
    if (exitOverlayBlockEl) {
      const overlayAutoCloseTimeout = 5000
      setTimeout(() => exitOverlayBlockEl.querySelector('.btn-close').click(), overlayAutoCloseTimeout)
    }
  } else {
    fieldEl.classList.remove('is-valid')
  }

  fieldEl.classList.add(feedbackFieldClass)
  feedbackEl.innerHTML = message
  fieldEl.after(feedbackEl)
}

document.addEventListener('alpha.loaded', (e) => {
  const alphaModuleMgr = e.detail.emitter
  const config = alphaModuleMgr.getConfig()
  const newsletterSubscriberForms = AlphaHelpers.smartQuerySelectorAll('.newsletter-subscribe-form')
  newsletterSubscriberForms.forEach((newsletterSubscribeFormEl) => {
    newsletterSubscribeFormEl.addEventListener('submit', (ev) => {
      ev.preventDefault()

      const wrapperEl = newsletterSubscribeFormEl.querySelector('div.form-control')
      const email = newsletterSubscribeFormEl.querySelector('.email-field').value.toLowerCase()
      if (email.trim() === '') {
        newsletterSubscribeSetFeedback(wrapperEl, 'invalid', config.i18n.notEmptyEmail)
        return
      }

      const mailTestRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!mailTestRegex.test(email)) {
        newsletterSubscribeSetFeedback(wrapperEl, 'invalid', config.i18n.invalidEmail)
        return
      }

      let type = 'invalid'
      let message = config.i18n.errorOccurred
      wrapperEl.classList.add('loading')
      const inputElements = newsletterSubscribeFormEl.querySelectorAll('input,button')
      inputElements.forEach((el) => el.setAttribute('disabled', 'disabled'))

      const formActionUrl = `${config.ajaxUrl}?action=newsletter_subscribe`
      const data = new FormData()
      data.append('email', email)

      fetch(formActionUrl, {
        method: 'POST',
        body: data
      })
        .then((response) => response.json())
        .then((data) => {
          message = data.message
          switch (data.status) {
            case 'ok':
              type = 'valid'
              break
            case 'ko':
            default:
              type = 'invalid'
              break
          }
        })
        .finally(() => {
          newsletterSubscribeSetFeedback(wrapperEl, type, message)
          wrapperEl.classList.remove('loading')
          inputElements.forEach((el) => el.removeAttribute('disabled'))
        })
    })
  })
})
